<template>
  <div>

    <div class="dropdown-menu">

      <div class="wrapper">
        <div class="row mt-2">
          <div class="company_name">
            <h1> {{ companyName }}
            </h1>
            <span class="status" :class="companyStatus">
            {{companyStatusText}}
          </span> <br>
            <span> {{ companyComment }} </span>
          </div>
        </div>
      </div>


      <div class="wrapper list_wrap">
        <ul class="kb-list">
          <li>
            <router-link to="/" class="kb-link"
                         @click.native="$emit('close')"
                         exact-active-class="active">
              Проекты
            </router-link>
          </li>

          <li v-if="companyStatus == 'connected'">
            <router-link to="/employers" class="kb-link"
                         @click.native="$emit('close')"
                         active-class="active"
                         exact-active-class="active"
            >
              Сотрудники
            </router-link>
          </li>

          <li v-if="companyStatus == 'connected'">
            <router-link to="/filials" class="kb-link"
                         @click.native="$emit('close')"
                         active-class="active"
                         exact-active-class="active">
              Филиалы
            </router-link>
          </li>
        </ul>
      </div>







      <div class="wrapper bottom">
        <div v-if="statusAuth" class="row ">
          <span class="name"> {{ fullname }}</span>
          <ul class="nav-top__actions">
            <li>
              <a href="#">Выйти</a>
            </li>
          </ul>
        </div>
      </div>

    </div>


  </div>
</template>

<script>
export default {
name: "DropdownMenu",
  computed: {
    fullname() {
      return this.$store.getters.fullName;
    },
    statusAuth() {
      return this.$store.getters.statusAuth !== 'noAuth'
    },
    compLinked () {
      return this.$store.getters.compLinked;
    },
    companyName () {
      return this.$store.getters.companyName;
    },
    companyStatus () {
      return this.$store.getters.companyStatus;
    },
    companyStatusText () {
      return this.$store.getters.companyStatusText;
    },
    companyComment () {
      return this.$store.getters.companyComment;
    },
    filialsList() {
      return this.$store.getters.filialsList;
    },
    usersList() {
      return this.$store.getters.usersList;
    },
  }
}
</script>

<style lang="scss" scoped>
.name {
  color: $text-gray;
}

.mt-2 {
  margin-top: 10px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
}


.bottom{
  margin-top: auto;
  margin-bottom: 30px;
}

.list_wrap {
  margin: auto 0;
}


.kb-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  li:first-of-type {
    border-top: solid 2px #f4f2f2;
  }

  li {
    border-bottom: solid 2px #f4f2f2;
  }



  .kb-link {
    display: inline-block;
    padding: 23px 0px;
    text-decoration: none;
    text-align: center;
    font-size: 18px;
    color: #818c99;
    letter-spacing: -0.7px;
    border-radius: 4px;
    line-height: normal;


    &.active {
      color: #031933;
      font-weight: bold;
    }
  }
}


.company_name {
  h1 {
    font-size: 18px;
    font-weight: normal;
    line-height: 1;
  }
}

.nav-top__actions {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin-left: 30px;
  }
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  top: 82px;
  z-index: 199;
  background: #fff;
  box-shadow: 0 17px 15px 0 rgba(159, 158, 158, 0.3);

  .about-project-link {
    font-size: 16px;
    color: #818c99;
    text-decoration: none;

  }

  .dropdown-menu__project-link {
    color: #031933;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 767px) {
    .dropdown-menu__project-link {
      margin-bottom: 0;
    }
  }

  .dropdown-support__title {
    font-size: 18px;
  }

  .dropdown-support__list {
    list-style: none;
    margin: 0;
    padding: 20px 0 0;
    display: flex;

    li {
      margin-right: 35px;

      &:last-child {
        margin-right: 0;
      }
    }

    .dropdown-support__link {
      text-decoration: none;
      margin-right: 10px;
    }
  }
}

.dropdown-menu__holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 48px;

  //.dropdown-menu-col-1 {
  //  width: 25%;
  //  flex: 0 0 25%;
  //}

  .dropdown-menu-col-2 {
    //width: 45%;
    //flex: 0 0 45%;
    //display: flex;

    .dropdown-menu_projects {
      //width: 50%;
      //flex: 0 0 50%;
      display: flex;
      flex-direction: column;
    }
  }

  //.dropdown-menu-col-3 {
  //  width: 30%;
  //  flex: 0 0 30%;
  //}
}


@media (max-width: 767px) {
  .dropdown-menu__holder {
    padding-bottom: 25px;
  }
}

</style>